import React from "react"
import FunStuff from "../components/FunStuff"
import Layout from "../components/layout"
import SEO from "../components/Seo"
const funstuff = {
  "twitter sentiment analysis": {
    url: "https://twitter-sentiment-analysis-web.vercel.app/",
    image: "",
    description: ""
  },
  "Dev in Persian Podcast": {
    url: "https://castbox.fm/channel/id4223969?country=us",
    image: "",
    description: ""
  },
  "PostPace": {
    url: "https://postpace.io",
    image: "",
    description: ""
  },
  "Our Wedding": {
    url: "https://vvedding.netlify.app",
    image: "",
    description: ""
  }
}
const NotFoundPage: React.FC<void> = () => (
  <Layout>
    <SEO title="Resume" />
    <FunStuff funstuff={funstuff} />
  </Layout>
)

export default NotFoundPage
