import React from "react"
import styled from "@emotion/styled"
import { centerFlexRow } from "../../helpers"
import { Link } from "gatsby"

const Page = styled.div`
  display: grid;
  width: 100%;
  place-items: center;
  font-size: 0.8 rem;
  color: black;


`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Resume = styled(Link)`
  padding: 5px 25px;
  text-decoration: none;
  color: rgb(59, 130, 246);
  border: 2px solid rgb(59, 130, 246);
  font-weight: 600;
  font-size: 0.75rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    color: white;
    background: rgb(59, 130, 246);
    opacity: 0.8;
    box-shadow: 0 14px 26px -12px rgba(131, 0, 255, 0.42),
      0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(131, 0, 255, 0.2);
  }
`

type HomeProps = {
  funstuff:any
}

const Home: React.FC<HomeProps> = ({funstuff}) => {
  return (
    <div className="flex flex-wrap items-center content-center justify-center min-h-screen align-middle ">

{Object.entries(funstuff).map(([key,value]:any)=><Resume className="m-2" key={key} to={value.url}>{key}</Resume>)}
<Resume className="m-2"  to='/'>Back to home</Resume>
    </div>
  )
}

export default Home
